import React from 'react'
import CampaignContextProvider from 'contexts/campaignContext/CampaignContextProvider'
import './src/themes/global.scss'

export const wrapRootElement = ({ element }) => (
	<CampaignContextProvider>{element}</CampaignContextProvider>
)

// This function get the clien Ip address and send for tagmanager as user_data
window.userIP = null
const getUserIpAddress = () => {
	var urlIpApi = 'https://jsonip.com/'

	fetch(urlIpApi)
		.then(function (response) {
			return response.json()
		})
		.then(function (data) {
			window.userIP = data.ip
			if (typeof window.gtag === 'function') {
				window.gtag('set', 'user_data', {
					client_ip_address: window.userIP,
				})
			}
			return
		})
		.catch(function (error) {
			// console.error('Ocorreu um erro ao obter o endereço IP:', error);
			console.error('Ocorreu um erro:', error)
		})
}

// call function imediately
getUserIpAddress()

//get params url - (2) to address url and (3) for params
const getParamsUrl = (index) => {
	if (typeof document !== 'undefined') {
		var url = document.URL
	}
	return url.split('/')[index]
}

//scripts to be added to the page
if (typeof document !== 'undefined') {
	// eslint-disable-next-line no-unused-vars
	var url = document.URL
}
let urlSplit = getParamsUrl(2)
let idClarity = 'broe5i62vj'
if (urlSplit === 'homolog.aurum.com.br') {
	idClarity = 'd2gphofxn5'
}
var clarity = [
	{
		htmlElement: 'script',
		atributtes: [
			{ name: 'type' },
			{ content: 'text/javascript' },
			{
				text: `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "${idClarity}");`,
			},
		],
	},
]

const adoptId = process.env.REACT_ADOPT_ID || ''
var adopt = [
	{
		htmlElement: 'meta',
		atributtes: [{ name: 'adopt-website-id' }, { content: adoptId }],
	},
	{
		htmlElement: 'script',
		atributtes: [
			{
				src: `//tag.goadopt.io/injector.js?website_code=${adoptId}`,
			},
			{ class: 'adopt-injector' },
		],
	},
	{
		htmlElement: 'script',
		atributtes: [{ text: 'window.adoptHideAfterConsent=true' }],
	},
]

//here we define the condition to append the scripts to the page
const appendConditions = () => {
	if (!window.localStorage.getItem('AdoptConsent')) {
		appendScript(adopt)
	}
	appendScript(clarity)
	sessionStorage.setItem(loadedScripts, 'true')
}

//funciton to append scripts to the page
const appendScript = (scripts) => {
	const htmlElements = scripts.map((scriptsData) => {
		let htmlElement = document.createElement(scriptsData.htmlElement)
		scriptsData.atributtes.forEach((scriptProperties) => {
			if (Object.keys(scriptProperties)[0] === 'text') {
				htmlElement.text = scriptProperties.text
			} else {
				htmlElement.setAttribute(
					Object.keys(scriptProperties),
					Object.values(scriptProperties)
				)
			}
		})
		return htmlElement
	})

	htmlElements.forEach((element) => {
		document.head.appendChild(element)
		scriptsActions()
	})
}

//any posterior action to be done after the scripts are appended to the page
const scriptsActions = () => {
	if (typeof window.sendAdoptCommand === 'function') {
		window.sendAdoptCommand('open')
	}
}

const loadedScripts = 'loadedScripts'
export const onInitialClientRender = () => {
	window.addEventListener('scroll', appendConditions, { once: true })
}
